/*!
 * 
 *  Custom Scripts
 * 
 */

//===================================================================		
//  Push the footer to bottom of page
//===================================================================

/*jQuery(document).ready(function( $ ) {
   var docHeight = $(window).height();
   var footerHeight = $('#footer').outerHeight();
   var footerTop = $('#footer').position().top + footerHeight;

   if (footerTop < docHeight) {
    $('#footer').css('margin-top', 10+ (docHeight - footerTop) + 'px');
   }
  });*/

//===================================================================		
// END of Push the footer to bottom of page
//===================================================================	

	
//===================================================================		
//  Start Cookie Bar
//===================================================================
jQuery(document).ready(function( $ ) {
$.cookieBar({
message:'We use cookies to give you the best online experience. By using this website you agree with our cookie policy.',
fixed: true,
acceptOnScroll: 300,
bottom: true,
effect: 'slide',
zindex: '5001',
policyButton: false,
policyText: 'Read more',
//policyURL: ''
policyURL: ''

});
});
//===================================================================		
// END of Cookie Bar
//===================================================================


//===================================================================		
//  SCROLL To Top
//===================================================================

// SCROLL to TOP
jQuery(document).ready(function( $ ) {
	
	//Check to see if the window is top if not then display button
	$(window).scroll(function(){
		if ($(this).scrollTop() >= 1200) {		// If page is scrolled more than 50px
			$('.scrollToTop').fadeIn(800);		// Fade in the arrow
		} else {
			$('.scrollToTop').fadeOut(800);		// Else fade out the arrow
		}
	});
	
	//Click event to scroll to top
	$('.scrollToTop').click(function(){					// When arrow is clicked
		$('html, body').animate({scrollTop : 0},800); 	// Scroll to top of body
		return false;
	});
	
});

//===================================================================		
// END of Comment
//===================================================================


//===================================================================		
//  Reviews Slider
//===================================================================

jQuery(document).ready(function( $ ) {
 
  $("#owl-reviews").owlCarousel({
	 margin: 80,
 	stagePadding: 50,
	nav:false, // Show next/prev buttons.
	//navText: ['next','prev'], // next/prev button text
	navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>','<i class="fa fa-chevron-right" aria-hidden="true"></i>'], // next/prev button text
	dots:true, // Show dots navigation.
	autoplaySpeed: 3000,
	navSpeed:9000,
	dotsSpeed:9000,
	//  singleItem:true,
	  
	    loop:true,
	autoplay:true,
 
      items : 1, 
      itemsDesktop : false,
      itemsDesktopSmall : false,
      itemsTablet: false,
      itemsMobile : false
 
  });
 
});
//===================================================================		
// END of  Owl Carousel
//===================================================================

//===================================================================		
//  Owl Tab - for Projects
//===================================================================

jQuery(document).ready(function( $ ) {
	
	
$('.owl-tab').owlCarousel({
	//fix for 1 image in loop
 loop: $("#owl-tab").find('.tab-item').size() > 1 ? true:false,
    margin:10,
 autoHeight: true,
	dots:false,
	//fix for hide nav for one Image
nav: $("#owl-tab").find(".tab-item").size() > 1 ? true:false,
	  singleItem:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
			        },
        1000:{
            items:1
        }
    },
	
	 onInitialized: setOwlStageHeight,
    onResized: setOwlStageHeight,
    onTranslated: setOwlStageHeight
 

});


function setOwlStageHeight(event) {
    var maxHeight = 0;
    $('.owl-tab .owl-item.active').each(function () { // LOOP THROUGH ACTIVE ITEMS
        var thisHeight = parseInt( $(this).height() );
        maxHeight=(maxHeight>=thisHeight?maxHeight:thisHeight);
    });
   // $('.owl-carousel').css('height', maxHeight );
    $('.owl-tab .owl-stage-outer').css('height', maxHeight ); // CORRECT DRAG-AREA SO BUTTONS ARE CLICKABLE
};

});
//===================================================================		
//  End Owl Tab - for Projects
//===================================================================


//===================================================================		
//  Supplier - Owl Carousel http://www.owlcarousel.owlgraphic.com/docs/api-options.html
//===================================================================
jQuery(document).ready(function( $ ) {
  $('.owl-gallery').owlCarousel({
/*	  	 margin: 80,
 	stagePadding: 50,*/
    loop:true,
	autoplay:true,
	autoplayHoverPause:true,
	autoplaySpeed: 3000,
	navSpeed:1000,
	dotsSpeed:1000,
	 autoHeight: false,
	nav:true, // Show next/prev buttons.
	//navText: ['next','prev'], // next/prev button text
	navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>','<i class="fa fa-chevron-right" aria-hidden="true"></i>'], // next/prev button text
	dots:false, // Show dots navigation.
	
    margin:10,
   // nav:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:4
        }
    },
	
		
/* onInitialized: setOwlStageHeight,
    onResized: setOwlStageHeight,
    onTranslated: setOwlStageHeight*/
	
});
});
//===================================================================		
// END of  Owl Carousel
//===================================================================



/*----------------------------------------------------------- 
/* Sticky & Shrinking Header
---------------------------------------------------------- */

    //caches a jQuery object containing the header element
    var header = jQuery(".site-header");
	
    jQuery(window).scroll(function() {
        var scroll = jQuery(window).scrollTop();

        if (scroll >= 50) {
         //   jQuery(".site-header").removeClass("no-shrink");
            jQuery(".site-header").addClass("shrink");

        } else {
            jQuery(".site-header").removeClass("shrink");
            //jQuery(".site-header").addClass("no-shrink");
		}
		
		});	


/*----------------------------------------------------------- 
 * End Sticky & Shrinking Header
---------------------------------------------------------- */

